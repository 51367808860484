import React, { useState, useEffect } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  MenuItem,
  Select,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  InputLabel,
  FormControl,
  Snackbar,
} from '@mui/material';
import { sum } from 'lodash';
import { Plans } from './tariff';
import UsernameField, { AuthProtocol } from './UsernameField';
import {
  BatchCreateRadiusRequest, CreateRadiusRequest, RadiusSummary, useBatchCreateRadiusUsersMutation,
} from './query/query';

export interface SubscriberFormProps {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  setSnackBarMessage: (arg: string) => void
  summary?: RadiusSummary
}

const SubscriberForm: React.FC<SubscriberFormProps> = ({
  isOpen, setIsOpen, setSnackBarMessage, summary = undefined,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const usernameField = register('username', {
    validate: (value) => value && value.split('@')[0].length > 0,
  });
  // const passwordField = register('password', { required: true });
  const tariffField = register('tariff', { required: true });
  const buildingIdField = register('buildingId', { required: true });
  const poolOrIp = (summary?.framedPool && summary?.framedPool !== '' ? 'ip_pool' : 'ip_address');

  const [
    networkField,
    setNetworkField,
  ] = useState<string>(summary?.framedPool === 'CGNAT-POOL' ? 'cgnat' : poolOrIp);

  const [
    authProtocolField,
    setAuthProtocolField,
  ] = useState<AuthProtocol>(summary?.technologyType ? summary?.technologyType : 'PPPoE');

  const ipField = register('ip', { required: networkField !== 'cgnat' });
  const routeField = register('routes', { });

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newNetworkField: string,
  ) => {
    e.preventDefault();
    if (newNetworkField == null) {
      return;
    }
    setNetworkField(newNetworkField);
  };

  const handleAuthProtocolFieldChange = (
    e: React.MouseEvent<HTMLElement>,
    newAuthProtocolField: AuthProtocol,
  ) => {
    e.preventDefault();
    if (newAuthProtocolField === null) {
      return;
    }
    setAuthProtocolField(newAuthProtocolField);
  };

  const handleClose = (e: React.SyntheticEvent) => {
    e.preventDefault();
    reset();
  };

  const [bulkCreate, {
    isLoading,
  }] = useBatchCreateRadiusUsersMutation();

  useEffect(() => {
    if (summary && summary?.username) {
      const id = (Plans.find((p) => p.down === summary.speedDownloadMbps && p.up === summary.speedUploadMbps)?.id || 0);
      if (summary?.framedPool && summary?.framedPool !== '') {
        setValue('ip', summary.framedPool);
      } else {
        setValue('ip', summary.framedIpAddress);
      }
      setValue('username', summary.username);
      setValue('buildingId', summary.buildingId);
      setValue('framedPool', summary.framedPool);
      setValue('tariff', id);
    }
  }, []);

  const postData = async ({
    username, ip, tariff, buildingId, framedPool,
  }: FieldValues) => {
    const plan = Plans.find((p) => p.id === tariff) || { down: 0, up: 0 };

    const userparts = username.split('@');

    const subscriber: CreateRadiusRequest = {
      username: userparts[0],
      domain: userparts[1],
      rsp: userparts[1],
      speedDownloadMbps: plan?.down,
      speedUploadMbps: plan?.up,
      technologyType: authProtocolField,
      buildingId,
      framedPool,
    };

    if (networkField === 'cgnat') {
      subscriber.framedPool = 'CGNAT-POOL';
    } else if (networkField === 'ip_pool') {
      subscriber.framedPool = ip;
    } else {
      subscriber.framedIpAddress = ip;
    }

    try {
      const data = await bulkCreate([subscriber]).unwrap();
      setSnackBarMessage(`subscriber ${data?.items[0].username} has been created succcessfully.`);
      setIsOpen(false);
    } catch (err) {
      setSnackBarMessage(String(err?.data) || 'error occurred');
    }
  };

  const handlePasswordGen = (evt: any) => {
    evt.preventDefault();
    const chars = 'abcdefghijklmnopqrstubwsyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let pw = '';
    for (let index = 0; index < 10; index += 1) {
      pw += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setValue('password', pw);
  };

  return (
    <Modal
      open
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ minWidth: '400px' }}>
        <Box
          component="form"
          onSubmit={handleSubmit(
            (d: FieldValues) => {
              postData(d);
            },
          )}
        >
          <CardHeader
            component="div"
            title="New subscriber"
          />
          <CardContent>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Authentication
              </Typography>
              <InputLabel
                sx={{
                  position: 'relative',
                  top: '1em',
                  ml: 1.5,
                  backgroundColor: 'white',
                  zIndex: 1,
                  display: 'inline-block',
                  padding: '0 0.4em',
                }}
                size="small"
                shrink
              >
                Protocol

              </InputLabel>
              <ToggleButtonGroup
                sx={{ mb: 0.5 }}
                exclusive
                value={authProtocolField}
                onChange={handleAuthProtocolFieldChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="PPPoE"
                >
                  PPPoE
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="IPoE"
                >
                  IPoE
                </ToggleButton>
              </ToggleButtonGroup>
              <UsernameField
                defaultValue={summary?.username || ''}
                setValue={setValue}
                authProtocol={authProtocolField}
                name={usernameField.name}
                error={!!errors.username}
                helperText={
                errors.username?.message
                  ? errors.username.message.toString()
                  : ''
                }
                required
              />
            </Box>
            <Box sx={{ mb: 1.5 }}>
              <Typography
                variant="body1"
                component="div"
              >
                Network
              </Typography>
              <ToggleButtonGroup
                sx={{ mt: 1, mb: 1.5 }}
                exclusive
                value={networkField}
                onChange={handleChange}
                fullWidth
              >
                <ToggleButton
                  color="primary"
                  value="ip_address"
                >
                  IP Address
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="ip_pool"
                >
                  IP Pool
                </ToggleButton>
                <ToggleButton
                  color="primary"
                  value="cgnat"
                >
                  CGNAT
                </ToggleButton>
              </ToggleButtonGroup>
              {networkField !== 'cgnat'
              && (
              <TextField
                sx={{ mt: 0 }}
                error={!!errors.ip}
                helperText={
                errors.ip?.message
                  ? errors.ip.message.toString()
                  : ''
                }
                onChange={ipField.onChange}
                onBlur={ipField.onBlur}
                inputRef={ipField.ref}
                name={ipField.name}
                id="ip_address"
                label={networkField === 'ip_address'
                  ? 'IP Address'
                  : 'IP Pool'}
                margin="normal"
                fullWidth
              />
              )}

              <TextField
                sx={{ mt: 0 }}
                error={!!errors.routes}
                helperText={
                errors.routes?.message
                  ? errors.routes.message.toString()
                  : ''
                }
                onChange={routeField.onChange}
                onBlur={routeField.onBlur}
                inputRef={routeField.ref}
                name={routeField.name}
                id="routes"
                label="Framed Routes"
                margin="normal"
                fullWidth
              />
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Tariff information
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <InputLabel id="tariff-plan-label">Tariff plan</InputLabel>
                <Select
                  onChange={tariffField.onChange}
                  onBlur={tariffField.onBlur}
                  inputRef={tariffField.ref}
                  name={tariffField.name}
                  error={!!errors.tariff}
                  id="tariff-plan"
                  label="Tariff plan"
                  defaultValue={summary?.speedDownloadMbps ? (Plans.find((p) => p.down === summary.speedDownloadMbps && p.up === summary.speedUploadMbps)?.id || 0) : 0}
                >
                  {Plans.map((tariff) => (
                    <MenuItem
                      key={tariff.id}
                      value={tariff.id}
                    >
                      {tariff.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography
                variant="body1"
                component="div"
              >
                Building ID
              </Typography>
              <FormControl
                margin="dense"
                fullWidth
              >
                <TextField
                  sx={{ mt: 0 }}
                  error={!!errors.buildingId}
                  helperText={
                    errors.buildingId?.message
                      ? errors.buildingId.message.toString()
                      : ''
                  }
                  margin="normal"
                  fullWidth
                  onChange={buildingIdField.onChange}
                  onBlur={buildingIdField.onBlur}
                  inputRef={buildingIdField.ref}
                  name={buildingIdField.name}
                  id="buildingId"
                  label="Building ID"
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions>
            <ButtonGroup fullWidth>
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Loading ...' : 'Create'}
              </Button>
              <Button
                size="large"
                color="error"
                variant="outlined"
                type="submit"
                disabled={isLoading}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </CardActions>
        </Box>
      </Card>
    </Modal>
  );
};

export default SubscriberForm;
