interface Plan {
  id: number;
  name: string;
  up: number;
  down: number;
}

const Plans: Array<Plan> = [
  {
    id: 1, up: 5, down: 5, name: '5/5 Mbps',
  },
  {
    id: 2, up: 25, down: 25, name: '25/5 Mbps',
  },
  {
    id: 3, up: 50, down: 50, name: '50/50 Mbps',
  },
  {
    id: 4, up: 100, down: 40, name: '100/40 Mbps',
  },
  {
    id: 5, up: 100, down: 100, name: '100/100 Mbps',
  },
  {
    id: 6, up: 150, down: 150, name: '150/150 Mbps',
  },
  {
    id: 7, up: 200, down: 40, name: '200/40 Mbps',
  },
  {
    id: 8, up: 200, down: 200, name: '200/200 Mbps',
  },
  {
    id: 9, up: 250, down: 250, name: '250/250 Mbps',
  },
  {
    id: 10, up: 350, down: 350, name: '350/350 Mbps',
  },
  {
    id: 11, up: 400, down: 400, name: '400/400 Mbps',
  },
  {
    id: 12, up: 500, down: 500, name: '500/500 Mbps',
  },
  {
    id: 13, up: 1000, down: 1000, name: '1000/1000 Mbps',
  },
];

export { Plans };
