import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import axios from 'axios';
import JWTDecode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import {
  Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Tooltip, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import { render } from 'react-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import { Decoded } from '../components/Dashboard';
import { ProctorData } from './models';
import {
  RadiusSummary,
  useBatchDeleteRadiusUsersMutation, useGetRadiusDetailsQuery, useListRadiusSummariesQuery, useSearchRadiusSummariesQuery,
} from './query/query';
import SubscriberForm from './AddForm';

interface ApiData {
  items: Array<ProctorData>
}

const ProctorDataTable: React.FC = () => {
  const [searchString, setSearchString] = useState('');
  const [searchParameter, setSearchParameter] = useState('id');

  const [showAdd, setShowAdd] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [currentSummary, setCurrentSummary] = useState<RadiusSummary | null>(null);

  const [debouncedSearchString, setDebouncedSearchString] = useState('');

  // Initial list fetch
  const {
    data: initialData,
    error: listError, isLoading: listLoading,
  } = useListRadiusSummariesQuery({ limit: 1000 });

  const [deleteSubs, { isLoading: deleteIsLoading }] = useBatchDeleteRadiusUsersMutation();

  // Search fetch
  const {
    data: searchData, error: searchError,
    isLoading: searchLoading,
  } = useSearchRadiusSummariesQuery(
    { searchString: debouncedSearchString, limit: 20, attribute: searchParameter },
    { skip: !debouncedSearchString }, // Skip search if no debounced string
  );

  // Determine rows for the table
  const rows = React.useMemo(() => [...(searchString ? searchData?.items || [] : initialData?.items || [])], [initialData?.items, listLoading, deleteIsLoading]);
  const renderError = (error: FetchBaseQueryError | SerializedError | undefined) => {
    if (!error) return null;

    if ('data' in error) {
      // Error is FetchBaseQueryError
      return (
        <Typography color="error">
          {
        (error.data as any)?.message || 'API Error occurred'
}
        </Typography>
      );
    }

    // Error is SerializedError
    return (
      <Typography
        color="error"
      >
        {(error as any)?.message || 'An unknown error occurred'}
      </Typography>
    );
  };

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  // Fetch data from the API
  const columns: GridColDef[] = [
    { field: 'username', headerName: 'Username', width: 300 },
    {
      field: 'password',
      headerName: 'Password',
      width: 200,
      renderCell: (params) => {
        const [showPassword, setShowPassword] = useState(false);

        // Copy password to clipboard
        const handleCopyToClipboard = () => {
          navigator.clipboard.writeText(params.value);
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {/* Display password or mask */}
            <span>{showPassword ? params.value : '**********'}</span>

            {/* Eye icon to toggle visibility */}
            <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
              <IconButton onClick={() => setShowPassword((prev) => !prev)} size="small">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>

            {/* Copy to clipboard icon */}
            <Tooltip title="Copy to Clipboard">
              <IconButton onClick={handleCopyToClipboard} size="small">
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    { field: 'framedIpAddress', headerName: 'Framed IP', width: 150 },
    { field: 'framedPool', headerName: 'Framed Pool', width: 150 },
    { field: 'speedDownloadMbps', headerName: 'Download Speed (Mbps)', width: 200 },
    { field: 'speedUploadMbps', headerName: 'Upload Speed (Mbps)', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setShowAdd(true);
            setCurrentSummary(params.row);
          }}
        >
          Edit
        </Button>
      ),
    },
    { field: 'rsp', headerName: 'RSP', width: 200 },
    { field: 'buildingId', headerName: 'Building ID', width: 200 },
  ];

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // Prevent closing on clickaway if desired
      return;
    }
    setSnackMessage('');
  };

  // Debounced search handler
  const handleSearchChange = useMemo(
    () => debounce((value: string) => {
      setDebouncedSearchString(value);
    }, 500),
    [],
  );
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value); // Update immediately for UI feedback
    handleSearchChange(event.target.value); // Debounce API call
  };

  const handleParameterChange = (event: SelectChangeEvent<string>) => {
    setSearchParameter(event.target.value as string);
  };

  return (
    <Box sx={{ paddingTop: 20, paddingLeft: 5, paddingRight: 5 }}>
      {/* Search Input */}
      {!!snackMessage && snackMessage !== '' && <Snackbar onClose={handleClose} message={snackMessage} open={!!snackMessage && snackMessage !== ''} autoHideDuration={3000} />}
      {showAdd && <SubscriberForm summary={currentSummary || undefined} isOpen={showAdd} setIsOpen={setShowAdd} setSnackBarMessage={setSnackMessage} />}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        {/* Dropdown for search parameters */}
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="search-parameter-label">Search By</InputLabel>
          <Select
            labelId="search-parameter-label"
            value={searchParameter}
            onChange={handleParameterChange}
            label="Search By"
          >
            <MenuItem value="id">Username</MenuItem>
            <MenuItem value="framedIPAddress">Framed IP Address</MenuItem>
            <MenuItem value="buildingId">Building ID</MenuItem>
            <MenuItem value="rsp">RSP</MenuItem>
            <MenuItem value="domain">Domain</MenuItem>
            <MenuItem value="framedPool">Framed IP Pool</MenuItem>
          </Select>
        </FormControl>

        {/* Search Input */}
        <TextField
          label={`Search ${searchParameter}`}
          variant="outlined"
          fullWidth
          value={searchString}
          onChange={onSearchChange}
        />
      </Box>

      {/* Loading Indicator */}
      {(listLoading || searchLoading) && <CircularProgress sx={{ marginBottom: 2 }} />}
      {renderError(listError)}
      {renderError(searchError)}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        loading={listLoading || searchLoading}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
        }}
        getRowId={(row) => row.username}
      />
      <Box sx={{ paddingTop: 2, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => deleteSubs({ items: selectionModel.map((v) => v.toString()) })}
          disabled={selectionModel.length === 0}
        >
          Delete Selected
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentSummary(null);
            setShowAdd(true);
          }}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};
export default ProctorDataTable;
