import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import meReducer from '../features/me/me';
import duplicatesReducer from '../features/radius/duplicates';
import checkItemsReducer from '../features/radius/check-items';
import replyItemsReducer from '../features/radius/reply-items';
import subscribersReducer from '../features/radius/subscribers';
import usersReducer from '../features/users/users';
import { radiusApi } from '../proctor/query/query';

export const store = configureStore({
  reducer: {
    me: meReducer,
    checkItems: checkItemsReducer,
    duplicates: duplicatesReducer,
    replyItems: replyItemsReducer,
    subscribers: subscribersReducer,
    users: usersReducer,
    [radiusApi.reducerPath]: radiusApi.reducer,

  },

  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(radiusApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
